/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

/*jshint multistr: true */
/*jshint loopfunc: true */


// Avoid `console` errors in browsers that lack a console.
(function () {
  var method;
  var noop = function () {
  };
  var methods = [
    'assert', 'clear', 'count', 'debug', 'dir', 'dirxml', 'error',
    'exception', 'group', 'groupCollapsed', 'groupEnd', 'info', 'log',
    'markTimeline', 'profile', 'profileEnd', 'table', 'time', 'timeEnd',
    'timeStamp', 'trace', 'warn'
  ];
  var length = methods.length;
  var console = (window.console = window.console || {});

  while (length--) {
    method = methods[length];

    // Only stub undefined methods.
    if (!console[method]) {
      console[method] = noop;
    }
  }
}());

Object.size = function(obj) {
  var size = 0, key;
  for (key in obj) {
    if (obj.hasOwnProperty(key)) {
      size++;
    }
  }
  return size;
};

function array_unique (inputArr) {
  //  discuss at: http://locutus.io/php/array_unique/
  //      note 1: The second argument, sort_flags is not implemented;
  //      note 1: also should be sorted (asort?) first according to docs
  //   example 1: array_unique(['Kevin','Kevin','van','Zonneveld','Kevin'])
  //   returns 1: {0: 'Kevin', 2: 'van', 3: 'Zonneveld'}
  //   example 2: array_unique({'a': 'green', 0: 'red', 'b': 'green', 1: 'blue', 2: 'red'})
  //   returns 2: {a: 'green', 0: 'red', 1: 'blue'}
  var key = '';
  var tmpArr2 = {};
  var val = '';
  var _arraySearch = function (needle, haystack) {
    var fkey = '';
    for (fkey in haystack) {
      if (haystack.hasOwnProperty(fkey)) {
        if ((haystack[fkey] + '') === (needle + '')) {
          return fkey;
        }
      }
    }
    return false;
  };
  
  for (key in inputArr) {
    if (inputArr.hasOwnProperty(key)) {
      val = inputArr[key];
      if (_arraySearch(val, tmpArr2) === false) {
        tmpArr2[key] = val;
      }
    }
  }
  return tmpArr2;
}

(function($) {
  var markers = {},
  filter_markers = [],
  map,
  execution_time,
  properties = {},
  unique_properties = [],
  ppp = 30,
  pagination = $('#properties-pagination .posts-pagination'),
  placesService,
  markerCluster,
  geocoder,
  map_icon,
  total_pages_local = 0,
  parsed_pages_local = 0,
  total_pages_remote = 0,
  parsed_pages_remote = 0,
  lat = 45.4214, lng = -75.6919,
  mls_listing_loaded,
  price_ranges = {
    '-200': {
      'min': '0',
      'max': '200000'
    },
    '200-400': {
      'min': '200000',
      'max': '400000'
    },
    '400-600': {
      'min': '400000',
      'max': '600000'
    },
    '600-800': {
      'min': '600000',
      'max': '800000'
    },
    '800-1m': {
      'min': '800000',
      'max': '1000000'
    },
    '1m+': {
      'min': '1000000'
    }
  };
  var gmap_styles = [{"featureType":"administrative","elementType":"labels.text.fill","stylers":[{"color":"#444444"}]},{"featureType":"landscape","elementType":"all","stylers":[{"color":"#f2f2f2"}]},{"featureType":"poi","elementType":"all","stylers":[{"visibility":"off"}]},{"featureType":"road","elementType":"all","stylers":[{"saturation":-100},{"lightness":45}]},{"featureType":"road.highway","elementType":"all","stylers":[{"visibility":"simplified"}]},{"featureType":"road.arterial","elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"transit","elementType":"all","stylers":[{"visibility":"off"}]},{"featureType":"water","elementType":"all","stylers":[{"color":"#f7ded9"},{"visibility":"on"}]}];
  var timer;
  var modal_data;
  var is_properties_page = js_vars.is_properties_page;
  
  var substringMatcher = function(strs) {
    return function findMatches(q, cb) {
      var matches, substringRegex;

      // an array that will be populated with substring matches
      matches = [];

      // regex used to determine if a string contains the substring `q`
      substrRegex = new RegExp(q, 'i');

      // iterate through the pool of strings and for any string that
      // contains the substring `q`, add it to the `matches` array
      $.each(strs, function(i, str) {
        if (substrRegex.test(str)) {
          matches.push(str);
        }
      });

      cb(matches);
    };
  };
  
  function pushWindowState(href) {
    if(!$('html').hasClass('oldie')) {
      window.history.pushState({}, "", href);
    }
  }
  
  function checkEmail(email) {
    var filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

    return (filter.test(email));
  }

  Number.prototype.formatMoney = function(_c, _d, _t){
    var n = this, 
      c = isNaN(_c = Math.abs(_c)) ? 2 : _c, 
      d = _d === undefined ? "." : _d, 
      t = _t === undefined ? "," : _t, 
      s = n < 0 ? "-" : "", 
      i = parseInt(n = Math.abs(+n || 0).toFixed(c)) + "", 
      j = (j = i.length) > 3 ? j % 3 : 0;
    return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
  };
  
  var scrollCompensate = function () {
    var inner = document.createElement('p');
    inner.style.width = "100%";
    inner.style.height = "200px";

    var outer = document.createElement('div');
    outer.style.position = "absolute";
    outer.style.top = "0px";
    outer.style.left = "0px";
    outer.style.visibility = "hidden";
    outer.style.width = "200px";
    outer.style.height = "150px";
    outer.style.overflow = "hidden";
    outer.appendChild(inner);

    document.body.appendChild(outer);
    var w1 = inner.offsetWidth;
    outer.style.overflow = 'scroll';
    var w2 = inner.offsetWidth;
    if (w1 === w2) {
      w2 = outer.clientWidth;
    }

    document.body.removeChild(outer);

    return (w1 - w2);
  };
  
  function showLoading() {
    timer = setTimeout(function() {
      $('.waiting').show('fast');
    }, 250);
  }

  function hideLoading() {
    $('.waiting').hide('fast');
    clearTimeout(timer);
  }
  
  function initializeMap(map_wrapper) {
    var styledMap = new google.maps.StyledMapType(gmap_styles, {name: "Styled Map"});
    var lat = 45.4214, lng = -75.6919;

    if(map_wrapper.attr('data-lat')) {
      lat = map_wrapper.attr('data-lat');
    }

    if(map_wrapper.attr('data-lng')) {
      lng = map_wrapper.attr('data-lng');
    }

    var point = new google.maps.LatLng(lat, lng);

    var myOptions = {
      zoomControl: true,
      zoomControlOptions: {
        style: google.maps.ZoomControlStyle.DEFAULT,
        position: google.maps.ControlPosition.LEFT_CENTER
      },
      zoom: is_properties_page ? 9 : 13,
      center: point,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      scrollwheel: is_properties_page ? false : true,
      streetViewControl: false
    };

    map = new google.maps.Map(map_wrapper.get(0), myOptions);
    map.mapTypes.set('map_style', styledMap);
    map.setMapTypeId('map_style');
    
    var map_icon = new google.maps.MarkerImage(js_vars.siteUrl+'/dist/images/map-marker.png', null, null, null, new google.maps.Size(44,52));

    if(is_properties_page) {
    } else {
      var marker = new google.maps.Marker({
        position: point,
        map: map,
        flat: true,
        icon: map_icon
      });
      
      if(map_wrapper.attr('data-address')) {
        geocoder = new google.maps.Geocoder();
        
        geocoder.geocode( { 'address': map_wrapper.attr('data-address')}, function(results, status) {
          if (status === google.maps.GeocoderStatus.OK) {
            map.setCenter(results[0].geometry.location);
            marker.setPosition(results[0].geometry.location);
          }
        });
      }
    }
  }
  
  function getBoundsPadding() {
    return {
      paddingTopLeft: [10, 10],
      paddingBottomRight: [10, $('#header').height()]
    };
  }
  
  function addControlPlaceholders(map) {
    var corners = map._controlCorners,
            l = 'leaflet-',
            container = map._controlContainer;

    function createCorner(vSide, hSide) {
      var className = l + vSide + ' ' + l + hSide;

      corners[vSide + hSide] = L.DomUtil.create('div', className, container);
    }

    createCorner('verticalcenter', 'left');
    createCorner('verticalcenter', 'right');
  }
  
  function initializePropertiesMap() {
    map = L.map('map', {
      center: [lat, lng],
      zoom: 11,
      scrollWheelZoom: false
    });
    
    var osm = new L.TileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png');

    var ggl = new L.Google('ROADMAP', {
      mapOptions: {
        styles: gmap_styles
      }
    });
    
    addControlPlaceholders(map);
    
    var zoom = L.control.zoom({
      position: 'verticalcenterleft'
    });
    map.addControl(zoom);
    
//    map.zoomControl.setPosition('verticalcenterleft');
//
//    L.control.scale({position: 'verticalcenterleft'}).addTo(map);

    map.addLayer(ggl);
    map.addControl(new L.Control.Layers( {'OSM':osm, 'Google':ggl}, {}));
    
    var LeafIcon = L.Icon.extend({
      options: {
        shadowUrl: js_vars.siteUrl+'/dist/images/map-marker-shadow.png',
        iconSize:     [44, 52],
        shadowSize:   [45, 35],
        shadowAnchor: [14, 10]
      }
    });

    map_icon = new LeafIcon({iconUrl: js_vars.siteUrl+'/dist/images/map-marker.png'});

    markerCluster = L.markerClusterGroup({spiderfyOnMaxZoom: true, showCoverageOnHover: false, zoomToBoundsOnClick: false, spiderfyDistanceMultiplier: 2});
    
    markerCluster.on('clusterclick', function (a) {
      var latLngBounds = a.layer.getBounds();

      map.fitBounds(latLngBounds, getBoundsPadding());
    });
  }
  
  function displayAjaxLoader() {
    $('#ajax-loader').addClass('on');
  }
  
  function hideAjaxLoader() {
    $('#ajax-loader').removeClass('on');
  }
  
  function getSearchFilters() {
    var ret = {};
    var hash = [];
    
    $('.filter-form').find('input[type="text"], select, input[type="checkbox"], input[type="radio"]').each(function() {
      if(($(this).is(':radio') || $(this).is(':checkbox'))) {
        if($(this).is(':checked')) {
          ret[$(this).attr('name')] = $(this).val();

          if($(this).val()) {
            hash.push($(this).attr('name')+'='+$(this).val());
          }
        }
      }
      else {
        ret[$(this).attr('name')] = $(this).val();

        if($(this).val()) {
          hash.push($(this).attr('name')+'='+$(this).val());
        }
      }
    });
    
    if(hash.length) {
      window.location.hash = "#/" + hash.join('/');
    }
    
    if($('.filter-form-static').length) {
      $('.filter-form-static').find('input[type="hidden"]').each(function() {
        ret[$(this).attr('name')] = $(this).val();
      });
    }
    
    if(ret['price-range']) {
      var price_range = ret['price-range'];
      
      if(price_ranges[price_range] && price_ranges[price_range].min) {
        ret.price_min = price_ranges[price_range].min;
      }
      
      if(price_ranges[price_range] && price_ranges[price_range].max) {
        ret.price_max = price_ranges[price_range].max;
      }
    }
    
    var tmp;
    
    if(ret.bedrooms) {
      tmp = ret.bedrooms;
      ret.bedrooms_min = tmp;
      delete ret.bedrooms;
    }
    
    if(ret.bathrooms) {
      tmp = ret.bathrooms;
      ret.bathrooms_min = tmp;
      delete ret.bathrooms;
    }
    
    return ret;
  }
  
  function loadPopupInfo(marker) {
    marker.find('.property-popup').each(function() {
      var p = $(this);
      
      p.find('.image .fa').removeClass('not-visible');

      if(p.find('.image img[data-src]').length) {
        p.find('.image img[data-src]').attr('src', p.find('.image img').attr('data-src'));
        p.find('.image .fa').addClass('not-visible');
      }
    });
  }
  
  function displayMapMarkers() {
    if(Object.size(properties)) {
      var markers_found = false;
      
      for(var i in properties) {
        var v = properties[i];
        
        if(v.location && v.location.lat && v.location.lng) {
          markers_found = true;
          
          var idx = v.mls_id;

          var img_src = '';
          if(v.image && v.image.small) {
            img_src = v.image.small;
          }

          var marker_id = 'marker-'+idx;
          var marker_info = [];
          var marker_image = "<img src='"+js_vars.siteUrl+"/dist/images/blank.png' data-src='"+img_src+"' class='img-responsive'>";
          var marker_link = "#";
          var marker_price = "";

          if(v.bedrooms && parseInt(v.bedrooms)) {
            if(parseInt(v.bedrooms) === 1) {
              marker_info[ marker_info.length ] = '1bd';
            }
            else {
              marker_info[ marker_info.length ] = v.bedrooms + 'bds';
            }
          }

          if(v.bathrooms && parseInt(v.bathrooms)) {
            if(parseInt(v.bathrooms) === 1) {
              marker_info[ marker_info.length ] = '1bth';
            }
            else {
              marker_info[ marker_info.length ] = v.bathrooms + 'bths';
            }
          }

          var title = "";
          if(v.location && v.location.address) {
            title = v.location.address.replace(", Canada", "").replace(",Canada", "");
          }

          if(v.url) {
            marker_link = v.url;
          }
          
          if(v.transaction_type && v.transaction_type.toLowerCase() === "for rent") {
            marker_price = "For rent";
          }
          else if(v.price) {
            marker_price = "$"+parseInt(v.price).formatMoney(0);
          }

          var marker_content = "<div class='property-popup' data-id='"+marker_id+"'>\n\
      <div class='image'>"+marker_image+"<i class='fa fa-spinner fa-spin fa-3x'></i></div>\n\
      <div class='title'>"+title+"</div>\n\
      <div class='price'>"+marker_price+"</div>\n\
      <div class='vt-available'></div>\n\
      <div class='info'>"+marker_info.join(', ')+"</div>\n\
      <a href='"+marker_link+"' class='link'></a></div>";

          var existing_marker = false;

          if(!existing_marker) {
            markers[ idx ] = L.marker([v.location.lat, v.location.lng], {
              icon:  map_icon,
              title: title,
              marker_id: marker_id
            });

            markers[ idx ].bindPopup( marker_content );

            markers[ idx ].on('popupopen', function() {
              loadPopupInfo( $(this.getPopup()._container).find('.leaflet-popup-content') );
            });

            markerCluster.addLayer( markers[ idx ] );
          }
        }
      }
      
      if(markers_found) {
        map.addLayer(markerCluster);

        var group = new L.featureGroup();
        group.addLayer(markerCluster);

        map.fitBounds(group.getBounds(), getBoundsPadding());
      }
    }
  }
  
  function displayListResultsPagination() {
    pagination = $('#properties-pagination .posts-pagination');
    
    pagination.empty();
    
    if(Object.size(properties)) {
      var max_pages = Math.ceil( Object.size(properties) / ppp );
      
      pagination.attr('data-max-pages', max_pages);
      
      if(max_pages > 1) {
        pagination.append( $('<a href="#" class="disabled" data-pagination="prev"><span class="fa fa-chevron-left"></span></a>') );
        
        for(var i=1; i<=max_pages; i++) {
          pagination.append( $('<a href="#" data-pagination="'+i+'" '+(i===1 ? 'class="current"' : '')+'>'+i+'</a>') );
        }
        
        pagination.append( $('<a href="#" data-pagination="next"><span class="fa fa-chevron-right"></span></a>') );
      }
    }
  }
  
  function displayListResults( page ) {
    if(!page) {
      page = 1;
    }
    
    $('#properties-list').empty();
    
    if(Object.size(properties)) {
      var min = ppp * (page-1), max = ppp * page;
      
      var counter = 0;
      
      for(var i in properties) {
        if(counter >= min && counter < max) {
          var v = properties[i];

          var property_link = "#";
          var property_image = '';
          var property_price = '';
          var bedrooms = "<li><span>Bedrooms:</span> <strong>"+(v.bedrooms && parseInt(v.bedrooms) > 0 ? v.bedrooms : "-")+"</strong></li>";
          var bathrooms = "<li><span>Bathrooms:</span> <strong>"+(v.bathrooms && parseInt(v.bathrooms) > 0 ? v.bathrooms : "-")+"</strong></li>";
          var property_info = [];

          if(v.image && v.image.medium_large) {
            property_image = " style=\"background-image: url('"+v.image.medium_large+"')\" ";
          }

          if(v.bedrooms) {
            if(parseInt(v.bedrooms) === 1) {
              property_info[ property_info.length ] = '1bd';
            }
            else {
              property_info[ property_info.length ] = v.bedrooms + 'bds';
            }
          }

          if(v.bathrooms) {
            if(parseInt(v.bathrooms) === 1) {
              property_info[ property_info.length ] = '1bth';
            }
            else {
              property_info[ property_info.length ] = v.bathrooms + 'bths';
            }
          }

          var title = v.title;

          if(v.url) {
            property_link = v.url;
          }

          if(v.transaction_type && v.transaction_type.toLowerCase() === "for rent") {
            property_price = "<div class='property-price'>For rent</div>";
          }
          else if(v.price) {
            property_price = "<div class='property-price'>$"+parseInt(v.price).formatMoney(0)+"</div>";
          }

          var data_mls_id = "";
          if(v.mls_id) {
            data_mls_id = " data-mls-id='"+v.mls_id+"' ";
          }

          var property_item = $("<div class='property-item' "+data_mls_id+" id='property-"+(v.mls_id ? v.mls_id : Math.random(1000))+"'>\n\
    <div>\n\
      <div class='property-image' "+property_image+"></div>\n\
      <div class='property-top-info'>\n\
        <div class='property-name'>"+title+"</div>\n\
        <div class='property-price'>"+property_price+"</div>\n\
      </div>\n\
      <div class='property-detail-info'>\n\
        <div class='beds-baths'>\n\
          <ul>"+bedrooms+bathrooms+"</ul>\n\
        </div>\n\
        <div class='property-description'>\n\
          "+v.description+"\n\
        </div>\n\
      </div>\n\
    </div>\n\
    <a href='"+property_link+"' class='ghost-link'></a>\n\
  </div>");

          $('#properties-list').append( property_item );
        }
        
        counter++;
      }
    }
    else {
      $('#properties-list').append( js_vars.no_properties_message );
    }
  }
  
  function addContentFromAjax( content ) {
    $(content).each(function(i, v) {
//      if(v.location && v.location.lat && v.location.lng && !properties[ v.mls_id ]) {
      if(!properties[ v.mls_id ]) {
        properties[ v.mls_id ] = v;
      }
    });
  }
  
  function processResultsAfterAjaxComplete() {
    hideAjaxLoader();
    
    displayListResults();

    displayMapMarkers();

    displayListResultsPagination();
    
    var end_time = new Date().getTime();
    console.log(end_time - execution_time);
  }
  
  function loadMarkersFromCDN( cdn, page ) {
    var search_filters = getSearchFilters();
    
    var max_page = 0;
    
    if(cdn === "local") {
      max_page = total_pages_local;
      
      if(max_page < 1) {
        loadMarkersFromCDN('remote');
      }
    }
    else if(cdn === "remote") {
      max_page = total_pages_remote;
      
      if(max_page < 1) {
        processResultsAfterAjaxComplete();
      }
    }
    
    for(var i=1; i<=max_page; i++) {
      search_filters.page = i;
      
      $.ajax({
        type : 'GET',
        url : js_vars.cdn[cdn],
        data : search_filters,
        beforeSend: function() {},
        success : function(data, status, request){
          if(data) {
            addContentFromAjax( data );
          }
        },
        complete: function(){
          if(cdn === "local") {
            parsed_pages_local++;
            
            if(parsed_pages_local === total_pages_local) {
              loadMarkersFromCDN('remote');
            }
          }
          else if(cdn === "remote") {
            parsed_pages_remote++;
            
            if(parsed_pages_remote === total_pages_remote) {
              processResultsAfterAjaxComplete();
            }
          }
        },
        dataType : 'json'
      });
    }
    
    return;
  }
  
  function getTotalPagesFromCDN() {
    var search_filters = getSearchFilters();
    
    search_filters.get_total_count = true;
    
    $.ajax({
      type : 'GET',
      url : js_vars.cdn.remote,
      data : search_filters,
      beforeSend: function() {},
      success : function(data, status, request){
        if(request && request.getResponseHeader('X-WP-TotalPages')) {
          total_pages_remote = parseInt( request.getResponseHeader('X-WP-TotalPages') );
        }
      },
      complete: function() {
        loadMarkersFromCDN('local');
      },
      dataType : 'json'
    });
  }
  
  function getTotalPagesFromLocal() {
    var search_filters = getSearchFilters();
    
    search_filters.get_total_count = true;
    
    $.ajax({
      type : 'GET',
      url : js_vars.cdn.local,
      data : search_filters,
      beforeSend: function() {},
      success : function(data, status, request){
        if(request && request.getResponseHeader('X-WP-TotalPages')) {
          total_pages_local = parseInt( request.getResponseHeader('X-WP-TotalPages') );
        }
      },
      complete: function() {
        getTotalPagesFromCDN();
      },
      dataType : 'json'
    });
  }
  
  function displayPropertiesMarkers() {
    $('#properties-list').empty();
    
    markers = {};
    properties = {};
    unique_properties = {};
    
    markerCluster.clearLayers();
    
    pagination.empty();
    
    total_pages_local = 0;
    parsed_pages_local = 0;
    total_pages_remote = 0;
    parsed_pages_remote = 0;
    
    displayAjaxLoader();
    
    $('#map-filters-form').slideUp('fast');
    $('#new-search').slideDown('fast');
    
    execution_time = new Date().getTime();
    
    getTotalPagesFromLocal();
  }
  
  function watchNavigationScroll() {
    if($(window).scrollTop() > ($('#page-header').outerHeight() - $('#header').outerHeight())) {
      $('body').addClass('fixed-nav');
    }
    else {
      $('body').removeClass('fixed-nav');
    }
  }
  
  $.fn.serializeObject = function()
  {
    var o = {};
    var a = this.serializeArray();
    $.each(a, function () {
      if (o[this.name] !== undefined) {
        if (!o[this.name].push) {
          o[this.name] = [o[this.name]];
        }
        o[this.name].push(this.value || '');
      } else {
        o[this.name] = this.value || '';
      }
    });
    return o;
};
  
  function getFilterAttributes(node) {
    var d = {},
            re_dataAttr = /^data-filter\-(.+)$/;

    $.each(node.get(0).attributes, function (index, attr) {
      if (re_dataAttr.test(attr.nodeName)) {
        var key = attr.nodeName.match(re_dataAttr)[1];
        d[key] = attr.nodeValue;
      }
    });

    return d;
  }
  
  function getFilterFields(form) {
    var filters = {};
    filters.action = 'get_site_data';
    
    if(parseInt(form.find('#filter-submitted').val()) === 1) {
      filters = form.serializeObject();
    }
    
    return filters;
  }
  

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        watchNavigationScroll();
        
        $(window).on('scroll resize', function() {
          watchNavigationScroll();
        });
        
        $('#menu-toggle').on('click', function(e) {
          e.preventDefault();
          
          $('#navigation').slideToggle('normal');
        });
        
        $('.map-wrapper').each(function() {
          if(!$(this).closest('#properties-map').length) {
            initializeMap($(this));
          }
        });
        
        $('#pdf-sendEmail').on('change', function() {
          if($(this).is(':checked')) {
            $('#show-pdf-email').slideDown('fast');
          }
          else {
            $('#show-pdf-email').slideUp('fast');
          }
        });
        
        $(document).on('click', '#calculate-button', function(e) {
          e.preventDefault();
          
          $(this).blur();
          
          var priceHome = $('.priceHome').val();
          var interest = $('.interest').val();
          var downPay = $('.downPay').val();
          var term = $('.term').val();
          var type = 'getCalc';
          var email = 'no';
          var eAddress = $('.email').val();
          if ($('.sendEmail').is(':checked')) {
            email = 'yes';
            
            if(!checkEmail(eAddress)) {
              $('.email').addClass('error');
              alert('Invalid email address.');
              return false;
            }
            else {
              $('.email').removeClass('error');
            }
          }
          var dataString = "type=" + type + "&priceHome=" + priceHome + "&interest=" + interest + "&downPay=" + downPay + "&term=" + term + "&email=" + email + "&eAddress=" + eAddress;
          
          showLoading();

          $.ajax({
            type: "GET",
            url: js_vars.siteUrl + '/lib/calc-ws.php',
            dataType: 'jsonp',
            data: dataString,
            error: function (response) {
            },
            success: function (response) {
              if (response) {
                if (response.error) {  // handle errors
                  modal_data = $(response.message);                   
                  hideLoading();
                } else {
                  var data = '<div class="innerContent"><h2>Mortgage Information</h2>' +
                          '<p>Amortization Schedule (P & I)</p>' +
                          '<div style="margin-right: '+scrollCompensate()+'px;"><table class="schedule table table-striped table-bordered no-mb">' +
                          '<thead>' +
                          '<tr><th width="10%">Payment</th><th width="30%">Interest</th><th width="30%">Principal</th><th width="30%">New Mortgage</th></tr>' +
                          '</thead><tbody></table></div>' +
                          '<div class="mortgage-table-wrapper"><table class="schedule table table-striped table-bordered">';
                  $.each(response.payment, function (key, val) {
                    if (val.value % 2 === 0) {
                      data += '<tr class="evenRow" name="evenRow">';
                    } else {
                      data += '<tr class="oddRow" name="oddRow">';
                    }
                    data += '<td width="10%">' + val.value + '</td><td width="30%">$' + val.interest +
                            '</td><td width="30%">$' + val.principal + '</td><td width="30%">$' + val.newMortgage + '</td></tr>';
                  });
                  data += '</tbody></table></div>';
                  data += response.blurb;
                  data += '</div>';
                  
                  if (js_vars.layout === 'modal') {
                    modal_data = $(data);
                  } else if (js_vars.layout === 'popup') {
//                    popup(data);
                  } else {
                    $('#schedule').html(data);
                  }

                  $('.sendEmail').prop('checked', false);
                  $('#show-pdf-email').hide();
                  hideLoading();
                }
                
                modal_data.addClass('white-popup-block');
                
                $.magnificPopup.open({
                  items: {
                    src: modal_data,
                    type: 'inline'
                  }
                }, 0);
                
                return false;
              }
            }
          });

          return false;
        });
        
        $('.property-images-row').magnificPopup({
          delegate: '.image-box a',
          type: 'image',
          tLoading: 'Loading image #%curr%...',
          mainClass: 'mfp-img-mobile',
          gallery: {
            enabled: true,
            navigateByImgClick: true,
            preload: [0,1]
          },
          image: {
            tError: '<a href="%url%">The image #%curr%</a> could not be loaded.'
          }
        });
        
        $('.request-showing-button').magnificPopup({
          type: 'inline',
          preloader: false
        });
        
        if($('.main-image-slides').length && $('.small-images-slides').length) {
          $('.main-image-slides').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            dots: false,
            fade: true,
            asNavFor: '.small-images-slides'
          });
         
          $('.small-images-slides').slick({
            slidesToShow: 5,
            slidesToScroll: 1,
            asNavFor: '.main-image-slides',
            arrows: false,
            dots: false,
            lazyLoad: 'ondemand',
            focusOnSelect: true,
            responsive: [
              {
                breakpoint: 767,
                settings: {
                  slidesToShow: 4
                }
              },
              {
                breakpoint: 480,
                settings: {
                  slidesToShow: 3
                }
              }
            ]
          });
        }
        
        $('.show-more-photos').on('click', function(e) {
          e.preventDefault();
          
          $('.more-photo').slideDown('fast');
          $(this).hide();
        });
        
        $(document).on('click', '#map-zoom-toggle', function(e) {
          e.preventDefault();
          
          $('body').toggleClass('map-fullscreen');
          
          setTimeout(function() {
            google.maps.event.trigger(map, "resize");
            map.invalidateSize();
          }, 100);
        });
        
        $(document).on('submit', '.filter-form', function(e) {
          e.preventDefault();
          
          var error = 0;
          
          if(!$(this).find('[name="type"]').val()) {
            error++;
            $(this).find('[name="type"]').addClass('error');
          }
          else {
            $(this).find('[name="type"]').removeClass('error');
          }
          
          if(!$(this).find('[name="price-range"]').val()) {
            error++;
            $(this).find('[name="price-range"]').addClass('error');
          }
          else {
            $(this).find('[name="price-range"]').removeClass('error');
          }
          
          if(error) {
            $(this).find('.error-message').show();
            
            return false;
          }
          else {
            $(this).find('.error-message').hide();
          }
          
          var filters = "";
          
          $(this).find('input[type="text"], select').each(function() {
            if($(this).val()) {
              filters += "/" + $(this).attr('name') + '=' + $(this).val();
            }
          });
          
          if($(this).closest('#properties-map').length) {
            displayPropertiesMarkers();
            
            return false;
          }
          
          if(filters) {
            window.location = $(this).attr('action') + '#' + filters;
          }
          else {
            window.location = $(this).attr('action');
          }
          
          return false;
        });
        
        if($('#properties-map').length) {
          initializePropertiesMap();
          
          if(window.location.hash) {
            var hash = window.location.hash.replace('#/', '').replace('#', '').split('/');

//            $('.filter-form').addClass('active');

            for(var i in hash) {
              var pair = hash[i].split('=');
              if(pair[0] && pair[1]) {
                if(pair[0] === 'search') {
                  $('.search-filter .search-field').val( pair[1] );
                }
                else if(pair[0] === 'type') {
                  $('.filter-form [name="type"] option[value="'+pair[1]+'"]').prop('selected', true);
                }
                else if(pair[0] === 'bedrooms') {
                  $('.filter-form [name="bedrooms"] option[value="'+pair[1]+'"]').prop('selected', true);
                }
                else if(pair[0] === 'bathrooms') {
                  $('.filter-form [name="bathrooms"] option[value="'+pair[1]+'"]').prop('selected', true);
                }
                else if(pair[0] === 'keyword') {
                  $('.filter-form [name="keyword"]').val( unescape( pair[1] ) );
                }
                else if(pair[0] === 'price-range') {
                  $('.filter-form [name="price-range"]').val( pair[1] );
                }
                else if(pair[0] === 'virtual-tour-only') {
                  $('.filter-form [name="virtual-tour-only"]').prop('checked', true);
                }
              }
            }
          }

          ajax_page = 1;

//          displayPropertiesMarkers();
          if (window.location.hash.indexOf('#/') >=0 ) {
            displayPropertiesMarkers();
          }

          $(document).on('click', '.posts-pagination a', function(e) {
            e.preventDefault();

            if($(this).hasClass('current') || $(this).hasClass('disabled')) {
              return false;
            }
            
            var page = $(this).attr('data-pagination');
            
            var active_page = parseInt( pagination.find('.current').attr('data-pagination') );
            
            var max_pages = parseInt( pagination.attr('data-max-pages') );
            
            if(page === "prev") {
              page = active_page - 1;
            }
            
            if(page === "next") {
              page = active_page + 1;
            }
            
            page = parseInt( page );
            
            if(page === 1) {
              pagination.find('[data-pagination="prev"]').addClass('disabled');
            }
            else {
              pagination.find('[data-pagination="prev"]').removeClass('disabled');
            }
            
            if(page === max_pages) {
              pagination.find('[data-pagination="next"]').addClass('disabled');
            }
            else {
              pagination.find('[data-pagination="next"]').removeClass('disabled');
            }
            
            pagination.find('.current').removeClass('current');
            pagination.find('[data-pagination="'+page+'"]').addClass('current');
            
            displayListResults(page);
            
            setTimeout(function() {
              $('html,body').stop().animate({
                'scrollTop': $('#properties-list').offset().top - $('#header').height()
              }, 'fast');
            }, 200);
          });
        }
        
        if($('#property-details-wrap') && $('#property-details-wrap').attr('data-title')) {
          $(document).prop('title', $('#property-details-wrap').attr('data-title'));
        }
        
        if(my_homes && Object.size(my_homes)) {
          properties = my_homes;
          
          displayMapMarkers();
        }
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        if($('.slideshow').length) {
          $('.slideshow').slick({
            fade: true,
            dots: false,
            arrows: true,
            autoplay: true,
            autoplaySpeed: 6000
          });
        }
        
        if($('.featured-properties .slides').length) {
          $('.featured-properties .slides').slick({
            dots: false,
            arrows: false,
            infinite: true,
            speed: 300,
            autoplay: true,
            autoplaySpeed: 4000,
            slidesToShow: 6,
            responsive: [
              {
                breakpoint: 1300,
                settings: {
                  slidesToShow: 5
                }
              },
              {
                breakpoint: 1200,
                settings: {
                  slidesToShow: 4
                }
              },
              {
                breakpoint: 960,
                settings: {
                  slidesToShow: 3
                }
              },
              {
                breakpoint: 640,
                settings: {
                  slidesToShow: 2
                }
              },
              {
                breakpoint: 480,
                settings: {
                  slidesToShow: 1
                }
              }
            ]
          });
        }
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
    'properties': {
      init: function() {
        $(document).on('click', '#new-search a', function(e) {
          e.preventDefault();
          
          $('#map-filters-form').slideDown('fast');
          $('#new-search').slideUp('fast');
        });
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
